.layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .layout-root-menu-item {
        > .layout-root-menu-item-text {
            font-size: 0.857rem;
            text-transform: uppercase;
            font-weight: 700;
            color: var(--surface-900);
            margin: 0.75rem 0;
        }

        > a {
            display: none;
        }
    }

    a {
        user-select: none;

        &.active-menu-item {
            > .layout-submenu-toggler {
                transform: rotate(-180deg);
            }
        }
    }

    li.active-menu-item {
        > a {
            .layout-submenu-toggler {
                transform: rotate(-180deg);
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        a {
            display: flex;
            align-items: center;
            position: relative;
            outline: 0 none;
            color: var(--text-color);
            cursor: pointer;
            padding: 0.75rem 1rem;
            border-radius: $borderRadius;
            transition: background-color $transitionDuration, box-shadow $transitionDuration;

            .layout-menu-item-icon {
                margin-right: 0.5rem;
            }

            .layout-submenu-toggler {
                font-size: 75%;
                margin-left: auto;
                transition: transform $transitionDuration;
            }

            &.active-route {
                font-weight: 700;
                color: var(--primary-color);
            }

            &:hover {
                background-color: var(--surface-hover);
            }

            &:focus {
                @include focused-inset();
            }
        }

        ul {
            overflow: hidden;
            border-radius: $borderRadius;

            li {
                a {
                    margin-left: 1rem;
                }

                li {
                    a {
                        margin-left: 2rem;
                    }

                    li {
                        a {
                            margin-left: 2.5rem;
                        }

                        li {
                            a {
                                margin-left: 3rem;
                            }

                            li {
                                a {
                                    margin-left: 3.5rem;
                                }

                                li {
                                    a {
                                        margin-left: 4rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
