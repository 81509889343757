* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
}

html {
    font-size: $scale;
}

body {
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: var(--surface-ground);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: var(--primary-color);
}

.layout-wrapper {
    min-height: 100vh;
}

.icon-x1 {
    transform: 1.5rem;
}

.icon-x2 {
    font-size: 2rem;
}

.icon-x3 {
    font-size: 2.5rem;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.ml-2 {
    margin-left: 2rem !important;
}

.mr-2 {
    margin-right: 2rem !important;
}
