@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.scss";
@import "primeicons/primeicons.css";
@import "@fortawesome/fontawesome-free/css/all.css";

@import "styles/layout.scss";

@import "ngx-toastr/toastr";

.header {
    .header-top {
        color: white;
        background-color: #004e70 !important;
    }

    .left-menu {
        flex-grow: 1;
    }

    .title {
        align-self: center;
        font-size: medium;
        font-weight: bold;
    }
}

.terms-subtitle {
    text-decoration-color: initial;
    font-weight: bold;
    text-decoration: underline;
}

.idb-not-allowed {
    cursor: not-allowed !important;
}

.idb-action-button {
    height: 1.5rem !important;
    width: 1.5rem !important;
    margin: 0.15rem !important;

    &:hover {
        > .p-button-icon {
            font-size: 1.25rem !important;
        }
    }

    > .p-button-icon {
        font-size: 1rem !important;
    }

    &:disabled {
        cursor: not-allowed !important;
    }
}

.idb-table-actions {
    text-align: left !important;
}

.idb-status {
    &.true,
    &.active,
    &.approved,
    &.approve {
        color: #00b050;
    }

    &.false,
    &.pending {
        color: #c00000;
    }

    &.deprovisioned,
    &.inactive,
    &.denied,
    &.deny {
        color: #38557a;
    }

    &.mail-validate {
        color: #ff9933;
    }
}

.idb-switch {
    width: 3.5rem;
    height: 1.7rem;
    position: relative;
    display: inline-block;
    user-select: none;
    vertical-align: middle;
    margin: 0.1rem;

    :not(.readonly, .disabled) {
        cursor: pointer;

        &.button {
            &:hover {
                font-size: 1.25rem;
            }
        }
    }

    .readonly {
        cursor: no-drop;
    }

    .disabled {
        cursor: not-allowed;
    }

    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0.85rem;
    }

    .button {
        position: absolute;
        width: 1.7rem;
        height: 1.7rem;
        font-size: 1rem;
        border-radius: 50%;
        border-width: 0.1rem;
        border-style: solid;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1.7rem;

        &.true {
            right: 0;
        }

        &.false {
            left: 0;
        }

        &.hide {
            color: transparent !important;
            background-color: transparent !important;
            border-color: transparent !important;
        }
    }
}

.idb-v-center {
    display: flex;
    align-items: center;
}

h1.idb-v-center,
h2.idb-v-center,
h3.idb-v-center,
h4.idb-v-center,
h5.idb-v-center,
h6.idb-v-center {
    margin-top: auto;
    margin-bottom: auto;
}

.idb-v-top {
    display: flex;
    align-items: flex-start;
}

h1.idb-v-top,
h2.idb-v-top,
h3.idb-v-top,
h4.idb-v-top,
h5.idb-v-top,
h6.idb-v-top {
    margin-top: 0;
    margin-bottom: auto;
}

.idb-v-botttom {
    display: flex;
    align-items: flex-end;
}

h1.idb-v-botttom,
h2.idb-v-botttom,
h3.idb-v-botttom,
h4.idb-v-botttom,
h5.idb-v-botttom,
h6.idb-v-botttom {
    margin-top: auto;
    margin-bottom: 0;
}

.idb-h-center {
    display: flex;
    justify-content: center;
}

.idb-red-b,
.idb-blue-b,
.idb-green-b,
.idb-yellow-b {
    border-style: solid;
}

.idb-red-b {
    border-color: red;
}

.idb-blue-b {
    border-color: blue;
}

.idb-green-b {
    border-color: green;
}

.idb-yellow-b {
    border-color: yellow;
}

p-autocomplete {
    > .p-inputwrapper {
        width: 100% !important;
        > .p-autocomplete-input {
            width: 100% !important;
        }
    }
}

.idb-menuitem-active {
    &:hover {
        > .p-menuitem-content {
            > .p-menuitem-link {
                > .p-menuitem-icon,
                > .p-menuitem-text {
                    font-weight: bold;
                }
            }
        }
    }

    > .p-menuitem-content {
        > .p-menuitem-link {
            > .p-menuitem-icon,
            > .p-menuitem-text {
                font-weight: bold;
            }
        }
    }
}

.idb-menuitem-normal {
    background-color: transparent;

    > .p-menuitem-content {
        background-color: transparent;
        > .p-menuitem-link {
            background-color: transparent;
            > .p-menuitem-icon {
                background-color: transparent;
            }
        }
    }
}

.idb-loading-spin {
    font-weight: 1 !important;
    font-size: 2rem !important;

    &.sm {
        font-size: 1rem !important;
    }

    &.md {
        font-size: 3rem !important;
    }

    &.lg {
        font-size: 5rem !important;
    }

    &.xl {
        font-size: 7rem !important;
    }
}

.p-dropdown-clear-icon {
    pointer-events: auto;
}

.idb-email-tooltip {
    white-space: nowrap;
    max-width: 300px;
}

app-save-cancel-buttons {
    &.start {
        align-items: flex-start !important;
    }
    &.center {
        align-items: center !important;
    }
    &.end {
        align-items: flex-end !important;
    }
}
