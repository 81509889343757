.layout-box-main {
    width: 100%;
    height: 100%;

    .layout-box-header {
        height: $layoutBoxHeaderHeight;
    }

    .layout-box-content {
        width: 100%;
        top: $layoutBoxHeaderHeight;
        position: absolute;
        height: calc(100% - ($layoutBoxHeaderHeight + 4.5rem));
        padding: 0;
        justify-content: space-between;
        transition: margin-left $transitionDuration;
    }
}
